<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type { Swiper as SwiperType } from 'swiper'

// Import Swiper styles
import 'swiper/css'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  slots: [{ name: 'default' }],
  description: 'Slider of Images that is clipped on the left or right',
  fields: {
    isRight: { label: 'Clips to the right', type: 'checkbox' },
    title: { type: 'text', label: 'Title' },
    description: { type: 'textarea', label: 'Description' },
    wideTitle: { type: 'checkbox', label: 'Wide Title', default: true },
    autoPlay: { type: 'checkbox', label: 'Auto Play Slider?', default: false },
    linkPosition: { type: 'select', label: 'Link Position', options: { underneath: 'Underneath', beside: 'Beside' } },
    buttonLink: { type: 'link', label: 'Button Link', enabled: false },
    textLink: { type: 'link', label: 'Text Link', enabled: false },
  },
  name: { label: 'Clipped Slider', group: 'Glue' },
  templates: [{
    label: 'Clipped Slider',
    fields: {
      buttonLink: { value: { text: 'view all dining', target: '_self', relationship: null, href: '\/new-page' } },
      description: { value: 'With over 40 restaurants and 3 Michelin starred chefs, you can see why Atlantis is an epicurean destination.' },
      isRight: { value: true },
      linkPosition: { value: 'underneath' },
      title: { value: 'ATLANTIS BAHAMAS - THE CULINARY CAPITAL OF THE CARIBBEAN' },
    },
  }],
})

const swiper: Ref<SwiperType | null> = ref(null)
const windowWidth = ref(1200)

const containerWidth = computed(() => {
  let width = '1200px'
  if (windowWidth.value > 1680) {
    width = '1450px'
  }
  else if (windowWidth.value > 1120) {
    const threefourths = windowWidth.value * 0.75
    const bleedWidth = (windowWidth.value * 0.25) / 2
    width = `${bleedWidth + threefourths}px`
  }
  else {
    width = '100%'
  }

  return width
})

const activeIndex = computed(() => {
  if (swiper.value)
    return Number.parseInt(swiper.value.activeIndex) - 1

  return null
})

const autoplayOptions = computed(() => {
  if (props.fields.autoPlay.value) {
    return {
      delay: 5000,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    }
  }

  return false
})

function onSwiper(swiperInstance: SwiperType) {
  swiper.value = swiperInstance
}

function next() {
  if (swiper.value)
    swiper.value.slideNext()
}

function prev() {
  if (swiper.value)
    swiper.value.slidePrev()
}

function goToSlide(index: number) {
  if (swiper.value)
    swiper.value.slideTo(index)
}

onMounted(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
})
</script>

<template>
  <div :class="{ 'big-container-left': !fields.isRight.value, 'big-container-right': fields.isRight.value }">
    <div style="height:0;">
&nbsp;
    </div>
    <div>
      <div>
        <div
          class="flex"
          :class="{
            'justify-end': fields.isRight.value,
            'justify-start': !fields.isRight.value,
          }"
        >
          <div :style="{ width: containerWidth }" class="pb-20">
            <div
              class="px-8 md:px-0 flex flex-col"
              :class="{
                'items-start': fields.isRight.value,
                'items-end text-right': !fields.isRight.value,
                'lg:flex-row-reverse items-end':
                  fields.linkPosition.value === 'beside'
                  && !fields.isRight.value,
                'lg:flex-row  items-end':
                  fields.linkPosition.value === 'beside'
                  && fields.isRight.value,
                'md:pr-12': fields.isRight.value,
                'md:pl-12': !fields.isRight.value,
              }"
            >
              <div
                class="flex-1"
                :class="{
                  'lg:pl-48':
                    fields.linkPosition.value === 'beside'
                    && !fields.isRight.value,
                  'lg:pr-48':
                    fields.linkPosition.value === 'beside'
                    && fields.isRight.value,
                }"
              >
                <div
                  v-if="fields.title.value"
                  class="headline mb-3"
                  :class="{
                    'max-w-110': fields.wideTitle.value,
                    'max-w-86': !fields.wideTitle.value,
                  }"
                >
                  {{ fields.title.value }}
                </div>
                <div
                  v-if="fields.description.value"
                  class="max-w-120 body-lg mb-6"
                >
                  {{ fields.description.value }}
                </div>
              </div>
              <div
                class="flex items-center space-x-8 mb-6"
                :class="{
                  'lg:ml-8':
                    fields.linkPosition.value === 'beside'
                    && !fields.isRight.value,
                  'lg:mr-8':
                    fields.linkPosition.value === 'beside'
                    && fields.isRight.value,
                }"
              >
                <a
                  v-if="fields.buttonLink.enabled"
                  :href="fields.buttonLink.value?.href"
                  :target="fields.buttonLink.value.target"
                  :rel="fields.buttonLink.value.rel"
                  class="glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase btn-ghost body-11 tracking-widest py-3 px-6 text-white font-bold"
                >
                  {{ fields.buttonLink.value.text }}</a>
                <a
                  v-if="fields.textLink.enabled"
                  :href="fields.textLink.value?.href"
                  :target="fields.textLink.value.target"
                  :rel="fields.textLink.value.rel"
                  class="special-learnmore -mb-1 uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border"
                >
                  {{ fields.textLink.value.text }}
                </a>
              </div>
            </div>

            <div class="relative md:ml-0 md:mr-0 overflow-hidden flex">
              <Swiper
                v-if="slice.elements.length > 0"
                slides-per-view="auto"
                :dir="fields.isRight.value ? 'ltr' : 'rtl'"
                :space-between="10"
                :loop="slice.elements.length > 1"
                :autoplay="autoplayOptions"
                :centered-slides="false"
                @swiper="onSwiper"
              >
                <SwiperSlide
                  v-for="(s, key) in slice.elements"
                  :key="key"
                  class="lg:mx-0 flex justify-center "
                  style="direction: ltr"
                >
                  <div class="h-full flex">
                    <VoixSlice
                      :slice="s"
                      :slice-index="key"
                      style="direction: ltr"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>

              <div v-else class="aspect-video w-full h-full bg-orange-200 flex justify-center items-center">
                <Icon name="heroicons:photo-20-solid" class="w-12 h-12 text-orange-500" />
              </div>

              <div
                v-if="slice.elements.length > 1"
                class="absolute z-50 inset-0 pointer-events-none flex justify-between items-center px-8"
              >
                <button class="slider-button border-gray-500" @click="prev">
                  <svg
                    class="w-7 h-7"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>

                <button class="slider-button border-gray-500" @click="next">
                  <svg
                    class="w-7 h-7 transform rotate-180"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="flex justify-center lg:justify-end px-4">
              <div class="flex space-x-3 mt-6">
                <button
                  v-for="(slide, n) in slice.elements"
                  :key="n"
                  class="h-1.5 w-1.5 rounded-full transistion-colors duration-500"
                  :class="{
                    'bg-gray-500': n !== activeIndex % slice.elements.length,
                    'bg-white': n === activeIndex % slice.elements.length,
                  }"
                  @click="goToSlide(n)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
.swiper-wrapper {
  @apply items-stretch;
}
</style>
